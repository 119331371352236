import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '@shared/components/layout/layout';
import { CIA_TEMPLATE } from '@shared/utils/constants';
import ButtonLinkArrow from '@shared/components/utils/button-link-arrow';
import { useGlobalFooter } from '@shared/hooks/use-global-footer';

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query NotFoundData {
      header: wordpressWpMenu(slug: {eq: "cia-menu"}) {
        ...Header
        menuItems {
          nodes {
            url
            uri
            path
            parentId
            parentDatabaseId
            label
            databaseId
            description
            childItems {
              nodes {
                id
                url
                uri
                path
                label
                parentDatabaseId
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout headerMenu={data.header?.menuItems?.nodes} template={CIA_TEMPLATE} footerMenu={useGlobalFooter()}>
      <section className="background-grid-black background-threads careers-threads">
        <div className="container pv120-90-60">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="hero-parent-title">
                <span className="h1 small label mb0 d-inline">404 Error</span>
              </div>
              <h1 className="mb30">The page you requested doesn’t exist.</h1>
              <div className="mt0 mb0 outside-border-left-red">
              
                <p>If you arrived at this page using a bookmark or favorites link, please update it accordingly.</p>
                <p>Please use the search form or the links below to find the information you seek.</p>
                <ul>
                    <li><a href="/contact-cia/">Contact Us</a> (by postal mail, by fax, by phone, or via our e-mail form)</li>
                    <li><a href="/stories/">News &amp; Stories</a> (formerly News &amp; Information)</li>
                    <li><a href="/careers/">CIA Careers</a> (find a job and submit a resume)</li>
                    <li><a href="/the-world-factbook/">The World Factbook</a> (our premier country reference)</li>
                    <li><a href="/resources/world-leaders/">World Leaders</a></li>
                    <li><a href="/resources/csi/">Center for the Study of Intelligence</a></li>
                    <li><a href="/readingroom/">FOIA</a> (Electronic Reading Room)</li>
                    <li><a href="/podcast/the-langley-files/">The Langley Files Podcast</a></li>
                    <li><a href="/spy-kids/">Spy Kids</a></li>
                </ul>
                <p>Thank you for visiting CIA.gov.</p>
                <p>The CIA Web Team</p>
                <ButtonLinkArrow id="/" text="Return to Homepage" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default NotFound;
